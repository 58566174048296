import React, { useState, useEffect } from "react";
import AdminRight from './adminRight';
import AdminLeft from './adminLeft';
import {
    AdminService,
    EmailService,
    EventService,
    UserService,
    FormService,
    FileService,
  } from "../../services";
import { update } from "js-sha1";

/*
    Contains left and right pane
    Retrieves data from the database and stores in cache, if it's not there already
*/

function AdminHomePage() {
    //console.log("adminHomePage")

    const [cachedOrgs, setCachedOrgs] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);

    const getOrgs = () => {
        return cachedOrgs
    }

    // useEffect for fetching data
    useEffect(() => {
        onLoad();
    }, []);

    const adminRightRef = React.useRef(null);

    const onLoad = async () => {

        setCachedOrgs(JSON.parse(localStorage.getItem('cachedOrgs')))
        // cache data for expiration and any updates
        var cacheExpiration = JSON.parse(localStorage.getItem('cacheExpiration'));
        var cacheUpdate = JSON.parse(localStorage.getItem('cacheUpdate'));
    
        var currentTime = Date.now();

        if(!cacheExpiration && !cacheUpdate){
            await updateCache();
        }

        if(cacheExpiration <= currentTime || cacheUpdate){
            localStorage.removeItem('cacheUpdate');
            await updateCache();
        } 

        setDataLoaded(true);

    }

    const updateCache = async () => {
        localStorage.removeItem('cachedUsers');
        localStorage.removeItem('cachedUserMeta');
        localStorage.removeItem('cachedOrgs');
        localStorage.removeItem('cachedOrgForms');
        localStorage.removeItem('cachedOrgEmails');
        localStorage.removeItem('cachedOrgFolders');
        localStorage.removeItem('cachedOrgDatabases');
        localStorage.removeItem('cachedOrgGroups');

        await Promise.all([
            AdminService.getUsers().then((res) => {
                localStorage.setItem('cachedUsers', JSON.stringify(res.data))
            }),

            UserService.getUserMetaAll().then((res) => {
                localStorage.setItem('cachedUserMeta', JSON.stringify(res.data))
            }),

            AdminService.getOrgs().then((res) => {
                setCachedOrgs(res.data);
                localStorage.setItem('cachedOrgs', JSON.stringify(res.data));
            }),
    
            FormService.getForms().then(res => {
                localStorage.setItem('cachedOrgForms', JSON.stringify(res.data));
            }),
    
            EmailService.getEmails().then(res => {
                localStorage.setItem('cachedOrgEmails', JSON.stringify(res.data));
            }),
    
            FileService.getFolders().then(res => {
                localStorage.setItem('cachedOrgFolders', JSON.stringify(res.data));
            }),
    
            FormService.getDatabases().then(res => {
                localStorage.setItem('cachedOrgDatabases', JSON.stringify(res.data));
            }),
    
            AdminService.getGroups().then(res => {
                localStorage.setItem('cachedOrgGroups', JSON.stringify(res.data));
            }),
        ]);
        
        
        localStorage.setItem('cacheExpiration', JSON.stringify(Date.now() + 60000));
        // localStorage.setItem('cacheUpdate', true);
    }


    return (
        <div className="admin-dash admin-grid">
            {dataLoaded ? (
                <>
                    <AdminLeft cachedOrgs={cachedOrgs} adminRightRef={adminRightRef} />
                    <AdminRight adminRightRef={adminRightRef} />
                </>
            ) : (
                <div></div>
            )}
        </div>
    );
}

export default AdminHomePage;