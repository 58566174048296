/*
    Services utilized by Box/Dropbox

    Includes: 

    getFilesLink  - returns link to specific file in box
    getFolderData - deprecated
    getFolderItem - deprecated

*/

import http from "../http-common";

class FileService {

    getFolders(){
        return http.get("/Folders", {
          headers: {
            'apiKey': process.env.REACT_APP_API_KEY
          },
        })
    }

    getFilesLink(data) {
        //alert(data)
        return http.get("/FolderLink", {
            headers: {
                'apiKey': process.env.REACT_APP_API_KEY
            },
            params: {
                guid: data
            }
        });
    }

    getFolderData(data) {
        return http.get("/FolderData", {
            params: {
                folder: data
            }
        });
    } // DEPRECATED?TODO

    getFolderItem(data) {
        return http.get("/FolderItem", {
            params: {
                path: data
            }
        });
    } // DEPRECATED?TODO
}

export default new FileService();