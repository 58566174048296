/*
    Services utilized by User 

    Includes: 

    getUserMeta - retrieves additional user info such as services available
    getUser     - retrieves user info, org designation, etc.
    getOrgName  - retrieves organization title 
*/

import http from "../http-common";
import cookie from "react-cookies";

class UserService {

  getUserMetaAll(){
    return http.get("/userMetaAll", {
      headers: {
        'apiKey': process.env.REACT_APP_API_KEY
      },
    })
  }

  getUserMeta(data) {
    return http.get("/userMeta", {
      headers: {
        'apiKey': process.env.REACT_APP_API_KEY
      },
      params: {
        uuid: data,
      },
    });
  }

  getUser(data) {
    var tokenExist = cookie.load("accessToken");

    return http.get("/user", {
      headers: {
        'apiKey': process.env.REACT_APP_API_KEY
      },
      params: {
        uuid: data,
        tokenExist: tokenExist,
      },
    });
  }

  getOrgName(data) {
    return http.get("/orgName", {
      headers: {
        'apiKey': process.env.REACT_APP_API_KEY
      },
      params: {
        guid: data,
      },
    });
  }
}

export default new UserService();
