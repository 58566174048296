import React, { useState, useEffect } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
//import AdminAnalytics from "./adminAnalytics";
import AdminView from "./adminView";
import { AdminService, FormService, UploadService } from "../../services";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-toastify/dist/ReactToastify.css";
import { deletePopup, failedPopup, successPopup } from "./Popup";
/*
    Separates the admin pane (RIGHT SIDE)

    contains the admin view as well as the save and edit functions

    This is where redux state is updated and middleware is queried
*/

const ValidError = () => {
  return (
    <p className="error bottom-space">
      <b>Your password must meet all the requirements below:</b>
      <br />
      &nbsp;&nbsp;&nbsp;At least 8 characters
      <br />
      &nbsp;&nbsp;&nbsp;At least one uppercase character (A-Z)
      <br />
      &nbsp;&nbsp;&nbsp;At least one lowercase character (a-z)
      <br />
      &nbsp;&nbsp;&nbsp;At least one number (0-9)
      <br />
      &nbsp;&nbsp;&nbsp;At least one special character (!@#$%…)
    </p>
  );
};

function AdminRight(props) {
  const user = useSelector((state) => state.isSelect);
  const createUser = useSelector((state) => state.createUser);
  const createOrg = useSelector((state) => state.createOrg);
  const [showLoader, setShowLoader] = useState(false);
  const [deleteHappens, setDeleteHappens] = useState(0);
  const [adminViewSelectedGroups, setAdminViewSelectedGroups] = useState([]);

  const [currentSelection, setCurrentSelection] = useState({
    name: "",
    id: "",
    type: "user",
  });

  const [currentOrg, setCurrentOrg] = useState({
    name: "",
    id: "",
  });
  const [currentUser, setCurrentUser] = useState({
    name: "",
    id: "",
  });
  const [canEdit, setEdit] = useState(false);
  const [crumbSelected, setCrumbSelected] = useState(false);

  let userChecked = false;
  let permanentUser = false;
  let show = false;

  // Unlocks ability to edit info
  useEffect(() => {
    updateInfo(user);
    if (user.actor.id === "blank") {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [user]);

  function handleEdit(e) {
    e.preventDefault();
    setEdit(!canEdit);
  }

  function handleCancel(e) {
    e.preventDefault();
    setDeleteHappens(deleteHappens + 1);
    setEdit(!canEdit);
    setCurrentSelection({
      name: "",
      id: "",
      type: "user",
    })
    setCurrentOrg({
      name: "",
      id: "",
    });
    setCurrentUser({
      name: "",
      id: "",
    });
  }

  // var format = `/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;`;
  // var res = specialChars.split("").map((el) => pass.includes(el));
  // console.log(res);
  // Submits modifications or new entry to database
  async function handleSave(e) {
    localStorage.setItem('cacheUpdate', true);
    e.preventDefault();
    setShowLoader(true);
    if (currentSelection.type === "user") {
      try {
        //                             Create New User
        if (createUser.user.new === true) {
          show = true;
          if (
            createUser.user.firstName === "" ||
            createUser.user.lastName === ""
          ) {
            failedPopup("First and Last Name is Required!");
          } else if (
            createUser.user.password !== createUser.user.confirmPassword
          ) {
            failedPopup("Password Not Matching!");
          } else if (
            !createUser.user.password.match(
              "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
            )
          ) {
            failedPopup(<ValidError />);
          } else if (createUser.user.guid === "") {
            failedPopup("Organization cannot be Empty!");
          } else if (createUser.user.type === ""){
            failedPopup("Role cannot be Empty!");
          } else {
            localStorage.setItem('cacheUpdate', true);
            AdminService.createUser(createUser)
              .then((res) => {
                successPopup(res.data);
                window.location.reload();
              })
              .catch((err) => {
                failedPopup(err.response.data.message);
              });
            show = false;
            setEdit(!canEdit);
          }
        } else {
          if (
            createUser.user.firstName === "" ||
            createUser.user.lastName === ""
          ) {
            failedPopup("First and Last Name is Required!");
          } else if (createUser.user.guid === "") {
            failedPopup("Organization cannot be Empty!");
          } else {
            show = true;
            localStorage.setItem('cacheUpdate', true);
            AdminService.updateUser(createUser).then((res) => {
              successPopup(res.data);
              window.location.reload();
            });
            show = false;
            setEdit(!canEdit); 
          }
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        //                       Create New org
        if (createOrg.new === true) {
          show = true;
          let resSendOrgLogo;
          let resSendOrgBack;

          const resOfCreateOrg = await AdminService.createOrg(createOrg);
          if (createOrg.org.logoData) {
            resSendOrgLogo = await UploadService.sendOrgLogo(
              createOrg.org.logoData
            );
            if (resSendOrgLogo) {
              console.log(resSendOrgLogo, "==========logo updated");
            }
          }
          if (createOrg.org.backData) {
            //console.log(JSON.stringify(createOrg));
            resSendOrgBack = await UploadService.sendOrgBack(
              createOrg.org.backData
            );
            if (resSendOrgBack) {
              console.log(resSendOrgBack, "==========Background updated");
            }
          }
          show = false;
          localStorage.setItem('cacheUpdate', true);
          successPopup(resOfCreateOrg.data);
          window.location.reload();
        } else {
          if(adminViewSelectedGroups.length !== 0){
            AdminService.addGroupsToOrg({groups: adminViewSelectedGroups, orgUID: createOrg.org.guid});
          }
          show = true;

          //Update Org 
          const organizationUpdateRes = await AdminService.updateOrg(createOrg);
          if (createOrg.org.logoData || createOrg.org.backData) {
            if (createOrg.org.logoData) {
              await UploadService.sendOrgLogo(createOrg.org.logoData); 
            }

            if (createOrg.org.backData) {
              await UploadService.sendOrgBack(createOrg.org.backData);
            }
            setDeleteHappens(deleteHappens + 1);
          }

          if (createOrg.orgFlags.hasForms) {
            FormService.updateOrgForms(createOrg).then((res) => {
              if (res.data[0] === 1) {
                console.log("Forms Updated!");
              }
            });
          }
          if (createOrg.orgFlags.hasDatabase) {
            FormService.updateOrgDatabase(createOrg).then((res) => {
              console.log(res);
            });
          }
          show = false;
          localStorage.setItem('cacheUpdate', true);
          successPopup(organizationUpdateRes.data);
          setEdit(!canEdit);
          window.location.reload();
        }
      } catch (err) {
        console.log(err);
      }
    }
    setShowLoader(false);
  }

  /////////////////////////////////////
  const handleRecover = () => {
    localStorage.setItem('cacheUpdate', true);
    if (currentSelection.type === "org") {
      localStorage.setItem('cacheUpdate', true);
      AdminService.unDeleteOrg(createOrg).then((res) => {
        if (res.data) {
          setDeleteHappens(deleteHappens + 2);
          successPopup(res.data);
          window.location.reload();
        }
      });
    } else {
      currentSelection.email = createUser.user.email;
      localStorage.setItem('cacheUpdate', true);
      AdminService.unDeleteUser(currentSelection).then((res) => {
        if (res.data) {
          setDeleteHappens(deleteHappens + 2);
          successPopup(res.data);
          window.location.reload();
        }
      });
    }
  };
  const handleAlert = () => {
    confirmAlert({
      overlayClassName: "overlay",
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <div>
              <h1>
                <b>Are you sure?</b>
              </h1>
              <h6 className="py-4">
                Do you really want to delete this{" "}
                {currentSelection.type === "org" ? "Organization" : "User"}?
              </h6>
            </div>
            {currentSelection.type === "org" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
                className="py-2"
              >
                <div>
                  <input
                    type="checkbox"
                    id="users"
                    name="users"
                    className="checkbox-input"
                    defaultValue={userChecked}
                    onClick={() => {
                      userChecked = !userChecked;
                    }}
                  />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="">
                  <label htmlfor="users">
                    <p>Delete all Users in this Organization as well?</p>
                  </label>
                </div>
              </div>
            )}
            <br />
            <br />
            <div className="confirm-section">
              <Button className="btn-bd" onClick={() => onClose()}>
                Cancel
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                className="btn-delete"
                onClick={() => {
                  handleDelete();
                  onClose();
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        );
      },
    });
  };

  const handlePermanent = () => {
    confirmAlert({
      overlayClassName: "overlay",
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <div>
              <h1>
                <b>
                  Permanently Delete{" "}
                  {currentSelection.type === "org" ? "Organization" : "User"}?
                </b>
              </h1>
              <h6 className="py-4">
                {/*  */}
                {/*  */}
                {currentSelection.type === "org" ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                    className="py-2"
                  >
                    <div>
                      <input
                        type="checkbox"
                        id="users"
                        name="users"
                        className="checkbox-input"
                        defaultValue={permanentUser}
                        onClick={() => {
                          permanentUser = !permanentUser;
                        }}
                      />
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div className="">
                      <label htmlfor="users">
                        <p>Delete all Users in this Organization as well?</p>
                      </label>
                    </div>
                  </div>
                ) : (
                  // <>
                  //   All users in the Organization will also be deleted.
                  //   <br />
                  // </>
                  <>Are you sure? </>
                )}
              </h6>
            </div>
            <br />
            <br />
            <div className="confirm-section">
              <Button className="btn-bd" onClick={() => onClose()}>
                Cancel
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                className="btn-delete"
                onClick={() => {
                  handlePermanentDelete();
                  onClose();
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        );
      },
    });
  };

  // /////////////

  const handleDelete = () => {
    localStorage.setItem('cacheUpdate', true);
    if (currentSelection.type === "user") {
      setShowLoader(true);
      currentSelection.email = createUser.user.email;

      AdminService.deleteUser(currentSelection).then((res) => {
        deletePopup(res.data, handleRecover);
        setDeleteHappens(deleteHappens + 1);
        setEdit(!canEdit);
        setShowLoader(false);
        window.location.reload();
      });
    } else {
      setShowLoader(true);
      createOrg.org.includeUsers = userChecked;
      AdminService.deleteOrg(createOrg).then((res) => {
        deletePopup(res.data, handleRecover);
        setDeleteHappens(deleteHappens + 1);
        setEdit(!canEdit);
        setShowLoader(false);
        window.location.reload();
      });
    }
  };
  //     Permanent Delete
  const handlePermanentDelete = () => {
    setShowLoader(true);
    localStorage.setItem('cacheUpdate', true);
    if (currentSelection.type === "user") {
      currentSelection.email = createUser.user.email;

      AdminService.permanentDeleteUser(currentSelection).then((res) => {
        deletePopup(res.data, handleRecover);
        setDeleteHappens(deleteHappens + 1);
        setEdit(!canEdit);
        setShowLoader(false);
        window.location.reload();
      });
    } else {
      setShowLoader(true);
      createOrg.org.includeUsers = permanentUser;
      AdminService.permanentDeleteOrg(createOrg).then((res) => {
        deletePopup(res.data, handleRecover);
        setDeleteHappens(deleteHappens + 1);
        setEdit(!canEdit);
        setShowLoader(false);
        window.location.reload();
      });
    }
  };
  // //////////////////////////////
  // Org breadcrumb
  function orgCrumb(e) {
    e.preventDefault();

    setEdit(false);

    setCurrentSelection({
      name: currentOrg.name,
      id: currentOrg.id,
      type: "org",
    });

    setCrumbSelected(true);
  }

  // User breadcrumb
  function userCrumb(e) {
    e.preventDefault();

    setEdit(false);

    setCurrentSelection({
      name: currentUser.name,
      id: currentUser.id,
      type: "user",
    });

    setCrumbSelected(false);
  }

  function updateInfo(user) {
    if (user.actor.id !== "") {
      if (user.actor.id !== currentSelection.id) {
        if (user.actor.type === "org") setCrumbSelected(true);
        else setCrumbSelected(false);

        //console.log(user);
        setCurrentSelection({
          name: user.actor.name,
          id: user.actor.id,
          type: user.actor.type,
        });
        setCurrentUser({
          name: user.user.name,
          id: user.user.id,
        });
        setCurrentOrg({
          name: user.org.name,
          id: user.org.id,
        });
      }
    }
  }
  
  // useEffect(() => {
  //   console.log(createUser, "=============Org data");
  //   console.log(currentSelection, "=============Org Selection");
  // }, [createUser, currentSelection]);

  return (
    <div className="admin-right">
      {/*<AdminAnalytics orgName={orgName} />*/}
      <div className="admin-breadcrumb">
        <div className="admin-breadcrumbs">
          {currentOrg.name !== "" && (
            <b
              className={crumbSelected ? "bc-selected" : "bc-not-selected"}
              onClick={(e) => orgCrumb(e)}
            >
              {currentOrg.name}
            </b>
          )}
          {currentUser.name !== "" && (
            <b
              className={crumbSelected ? "bc-not-selected" : "bc-selected"}
              onClick={(e) => userCrumb(e)}
            >
              {currentUser.name}
            </b>
          )}
        </div>
        {/*                  user button starts              */}
        {currentSelection.name !== "" && currentSelection.type === "user" ? (
          currentSelection.name === "blankuser" ? (
            <div>
              {showLoader && (
                <Spinner animation="border" role="status"></Spinner>
              )}
              &nbsp;&nbsp;&nbsp;
              <Button className="btn-edit" onClick={(e) => handleSave(e)}>
                Save
              </Button>
              <Button className="btn-cancel" onClick={(e) => handleCancel(e)}>
                Cancel
              </Button>
            </div>
          ) : createUser.user.isDeleted ? (
            <div>
              <Button
                style={{
                  backgroundColor: "#fc444a",
                  color: "white",
                  fontSize: "1.2rem",
                  border: "none",
                  padding: "0.5rem 1.5rem",
                  marginLeft: "5px",
                }}
                onClick={handlePermanent}
              >
                Permanently Delete
              </Button>
              &nbsp; &nbsp;
              <Button className="btn-edit" onClick={handleRecover}>
                Undo Delete
              </Button>
            </div>
          ) : (
            <div>
              {canEdit ? (
                <div>
                  <Button
                    style={{
                      backgroundColor: "#fc444a",
                      color: "white",
                      fontSize: "1.2rem",
                      border: "none",
                      padding: "0.5rem 1.5rem",
                      marginRight: "5px",
                    }}
                    onClick={handleAlert}
                  >
                    Delete
                  </Button>
                  <Button className="btn-edit" onClick={(e) => handleSave(e)}>
                    Save
                  </Button>
                  <Button
                    className="btn-cancel"
                    onClick={(e) => handleCancel(e)}
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                <div>
                  {showLoader && (
                    <Spinner animation="border" role="status"></Spinner>
                  )}
                  &nbsp;&nbsp;&nbsp;
                  <Button className="btn-edit" onClick={(e) => handleEdit(e)}>
                    Edit
                  </Button>
                </div>
              )}
            </div>
          )
        ) : (
          ""
        )}

        {/*                   user buttons closed                  */}
        {/*            org button start                  */}
        {currentSelection.name !== "" && currentSelection.type === "org" ? (
          currentSelection.name === "blankorg" ? (
            <div>
              {showLoader && (
                <Spinner animation="border" role="status"></Spinner>
              )}
              &nbsp;&nbsp;&nbsp;
              <Button className="btn-edit" onClick={(e) => handleSave(e)}>
                Save
              </Button>
              <Button className="btn-cancel" onClick={(e) => handleCancel(e)}>
                Cancel
              </Button>
            </div>
          ) : createOrg.org.isDeleted ? (
            <div>
              <Button
                style={{
                  backgroundColor: "#fc444a",
                  color: "white",
                  fontSize: "1.2rem",
                  border: "none",
                  padding: "0.5rem 1.5rem",
                  marginLeft: "5px",
                }}
                onClick={handlePermanent}
              >
                Permanently Delete
              </Button>
              &nbsp; &nbsp; &nbsp;
              <Button className="btn-edit" onClick={handleRecover}>
                Undo Delete
              </Button>
            </div>
          ) : (
            <div>
              {canEdit ? (
                <div>
                  {showLoader && (
                    <Spinner animation="border" role="status"></Spinner>
                  )}
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    style={{
                      backgroundColor: "#fc444a",
                      color: "white",
                      fontSize: "1.2rem",
                      border: "none",
                      padding: "0.5rem 1.5rem",
                      marginRight: "5px",
                    }}
                    onClick={handleAlert}
                  >
                    Delete
                  </Button>
                  <Button className="btn-edit" onClick={(e) => handleSave(e)}>
                    Save
                  </Button>
                  <Button
                    className="btn-cancel"
                    onClick={(e) => handleCancel(e)}
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                <div>
                  <Button className="btn-edit" onClick={(e) => handleEdit(e)}>
                    Edit
                  </Button>
                </div>
              )}
            </div>
          )
        ) : (
          ""
        )}

        {/*         org buttons endss                */}
      </div>
      <div className="admin-main-view">
        {currentSelection.name !== "" ? (
          <Form>
            <fieldset disabled={!canEdit}>
              <AdminView
                actor={currentSelection.name}
                isOrg={currentSelection.type}
                actorID={currentSelection.id}
                canEdit={canEdit}
                email={user}
                deleteHappens={deleteHappens}
                selectedGroups={adminViewSelectedGroups}
                setAdminViewSelectedGroups={setAdminViewSelectedGroups}
                adminRightRef={props.adminRightRef}
                // updateData={}
              />
            </fieldset>
          </Form>
        ) : (
          <div className="admin-blank-view">
            <h1 className="h1-admin">Welcome back to DigitalRange</h1>
          </div>
        )}
      </div>
      <h6>
        <ToastContainer hideProgressBar style={{ width: "400px" }} />
      </h6>
    </div>
  );
}

export default AdminRight;
//
