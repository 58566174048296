/*
    Services utilized by 123Forms

    Includes: 

    getFormsLink - retrieves tokenized link to 123Forms dashboard
    getOrgForms - retrieves forms token

*/

import http from "../http-common";

const header = {
  headers: {
    'apiKey': process.env.REACT_APP_API_KEY
  }
}

class FormService {


  getForms(){
    return http.get("/Forms", {
      headers: {
        'apiKey': process.env.REACT_APP_API_KEY
      },
    })
  }

  getDatabases(){
    return http.get("/Databases", {
      headers: {
        'apiKey': process.env.REACT_APP_API_KEY
      },
    })
  }

  getFormsLink(data) {
    return http.get("/FormsLink", {
      headers: {
        'apiKey': process.env.REACT_APP_API_KEY
      },
      params: {
        guid: data,
      },
    });
  }

  getOrgForms(data) {
    return http.get("/orgForms", {
      headers: {
        'apiKey': process.env.REACT_APP_API_KEY
      },
      params: {
        guid: data,
      },
    });
  }

  updateOrgForms(data) {
    return http.post("/orgForms", data, header);
  }

  getOrgDatabase(data) {
    return http.get("/orgDatabase", {
      headers: {
        'apiKey': process.env.REACT_APP_API_KEY
      },
      params: {
        guid: data,
      },
    });
  }

  updateOrgDatabase(data) {
    
    return http.post("/orgDatabase", data, header);
  }


  // UPDATE FORMS USER

  // CREATE FORMS USER
}

export default new FormService();
