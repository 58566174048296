import {
    Button,
    Row,
    Col,
    Table,
    FormLabel,
    FormControl,
    Container,
  } from "react-bootstrap";
  import SelectSearch from "react-select-search";
  import Moment from "react-moment";

export default function OrgView(props){
    return(
        <Container fluid>   
              <Row className="admin-interior-view">
                <Col md={"12"} lg={"6"}>
                  <h1 className="h1-a zero">
                    {props.orgInfo.companyName &&
                      props.orgInfo.companyName.replaceAll("_", " ")}{" "}
                  </h1>
                  <Row>
                    <Col className="service-info mx-4 my-2">
                      <b className="info-title">Name:</b>
                      <input
                        className="admin-input"
                        type="text"
                        value={props.orgInfo.companyName}
                        //defaultValue={orgInfo.companyName}
                        onChange={(e) => {
                          props.setOrgInfo((prevState) => ({
                            ...prevState,
                            companyName: e.target.value,
                          }));
                          props.handleOrgUpdate();
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="a-row">
                    <Col className="admin-col col-little a-col">
                      <b className="info-title">Logo: </b>
                      {props.orgInfo.hasLogo === true ? (
                        <>
                          {props.orgInfo.logoData ? (
                            <figure className="admin-org-logo">
                              <img src={props.orgInfo.logoPreview} alt="" />
                            </figure>
                          ) : (
                            <figure className="admin-org-logo">
                              <img
                                src={
                                  "https://digital-range-v2-thumbnails.s3.amazonaws.com/organizationLogos/" +
                                  props.orgInfo.logoUrl
                                }
                                alt=""
                              />
                            </figure>
                          )}
                          {props.canEdit && (
                            <button
                              style={{
                                borderRadius: "20px",
                                width: "20px",
                                height: "20px",
                                backgroundColor: "red",
                                border: "1px solid red",
                                color: "white",
                                fontSize: "10px",
                              }}
                              onClick={() => {
                                props.setOrgInfo((prevState) => ({
                                  ...prevState,
                                  hasLogo: false,
                                  logoData: null,
                                }));
                                props.handleOrgUpdate();
                              }}
                            >
                              <b>X</b>
                            </button>
                          )}
                        </>
                      ) : (
                        <FormLabel className="a-label">
                          <FormControl
                            type="file"
                            className="custom-file-input"
                            onChange={(e) => {
                              const data = new FormData();
                              data.append(
                                "logo",
                                e.target.files[0],
                                props.orgInfo.randomId
                              );

                              props.setOrgInfo((prevState) => ({
                                ...prevState,
                                logoData: data,
                                hasLogo: true,
                                logoPreview: URL.createObjectURL(
                                  e.target.files[0]
                                ),
                              }));
                              props.handleOrgUpdate();
                            }}
                          />
                        </FormLabel>
                      )}
                    </Col>
                    <Col className="admin-col a-col">
                      <b className="info-title">Background Image: </b>
                      {props.orgInfo.hasImage ? (
                        <>
                          {props.orgInfo.backPreview ? (
                            <img
                              className="admin-org-bg"
                              src={props.orgInfo.backPreview}
                              alt=""
                            />
                          ) : (
                            <img
                              className="admin-org-bg"
                              src={
                                "https://digital-range-v2-thumbnails.s3.amazonaws.com/organizationBackgrounds/" +
                                props.orgInfo.backgroundUrl
                              }
                              alt=""
                            />
                          )}
                          &nbsp;
                          {props.canEdit && (
                            <button
                              style={{
                                borderRadius: "20px",
                                width: "20px",
                                height: "20px",
                                backgroundColor: "red",
                                border: "1px solid red",
                                color: "white",
                                fontSize: "10px",
                              }}
                              onClick={() => {
                                props.setOrgInfo((prevState) => ({
                                  ...prevState,
                                  hasImage: false,
                                  backData: null,
                                }));
                                props.handleOrgUpdate();
                              }}
                            >
                              <b>X</b>
                            </button>
                          )}
                        </>
                      ) : (
                        <FormLabel className="a-label">
                          <FormControl
                            type="file"
                            className="custom-file-input"
                            onChange={(e) => {
                              const data = new FormData();
                              data.append(
                                "back",
                                e.target.files[0],
                                props.orgInfo.randomId
                              );
                              props.setOrgInfo((prevState) => ({
                                ...prevState,
                                backData: data,
                                hasImage: true,
                                backPreview: URL.createObjectURL(
                                  e.target.files[0]
                                ),
                              }));
                              props.handleOrgUpdate();
                            }}
                          />
                        </FormLabel>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="admin-col a-col">
                      <b className="info-title">Groups: </b>
                      <SelectSearch
                        closeOnSelect={false}
                        printOptions="on-focus"
                        multiple
                        placeholder="Select Groups"
                        value={props.defaultSelected}
                        onChange={(e) => {
                          props.handleSelectSearch(e);
                          props.handleOrgUpdate();
                        }}
                        options={props.groups}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="admin-col">
                      <b className="info-title">
                        Users:{" "}
                        {props.orgInfo.users === undefined ? 0 : props.orgInfo.users.length}
                      </b>
                      <Table responsive className="org-users-table">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Last Seen</th>
                          </tr>
                        </thead>
                        <tbody>

                          {props.orgInfo.users &&
                            props.orgInfo.users.map((user) => (
                              <tr onClick={(e) => props.handleInnerSelect(e, user)}>
                                <td key={user + "-date"}>{user.name}</td>
                                <td>
                                  <Moment format="M/D/YY h:mm A">
                                    {props.userUpdatedAt[user.uuid]}
                                    {/*console.log(userUpdatedAt[user.uuid])*/}
                                  </Moment>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Col>

                <Col className="my-5" md={"12"} lg={"6"}>
                  <div className="actor-org-services">

                    {/*EMAIL*/}
                    <Button
                      className={props.orgFlags.hasEmail ? "btn-bc" : "btn-bd"}
                      onClick={(e) => props.handleButtonClick(e, "hasEmail")}
                    >
                      <i className="icon-user icon-e"></i>Email
                    </Button>



                    {/*FORMS*/}
                    <Button
                      className={props.orgFlags.hasForms ? "btn-bc" : "btn-bd"}
                      onClick={(e) => props.handleButtonClick(e, "hasForms")}
                    >
                      <i className="icon-user icon-f"></i>Forms
                    </Button>

                    {/*FILES*/}
                    <Button
                      className={props.orgFlags.hasFiles ? "btn-bc" : "btn-bd"}
                      onClick={(e) => props.handleButtonClick(e, "hasFiles")}
                    >
                      <i className="icon-user icon-fl"></i>Files
                    </Button>

                    {/*DATABASE*/}
                    <Button
                      className={props.orgFlags.hasDatabase ? "btn-bc" : "btn-bd"}
                      onClick={(e) => props.handleButtonClick(e, "hasDatabase")}
                    >
                      <i className="icon-user icon-db"></i>Database
                    </Button>
                  </div>
                  {props.orgFlags.hasEmail && (
                    <div className="admin-service">
                    <b className="service-title">Email</b>
                    <div className="service-info">
                        <b className="info-title">Client ID:</b>
                        <input
                        className="admin-input"
                        type="text"
                        value={props.orgInfo.clientID}
                        //defaultValue={userInfo.clientID}
                        onChange={(e) => {
                            e.persist();
                            props.setOrgInfo((prevState) => ({
                            ...prevState,
                            clientID: e.target.value,
                            }));
                            props.handleUpdate();
                        }}
                        />
                    </div>
                    <div className="service-info">
                        <b className="info-title">Add Subscriber Link:</b>
                        <input
                        className="admin-input"
                        type="text"
                        value={props.orgInfo.subLink}
                        //defaultValue={userInfo.subLink}
                        onChange={(e) => {
                            e.persist();
                            props.setOrgInfo((prevState) => ({
                            ...prevState,
                            subLink: e.target.value,
                            }));
                            props.handleUpdate();
                        }}
                        />
                    </div>
                    </div>
                )}{" "}
                  {props.orgFlags.hasForms && (
                    <div className="admin-service">
                      <b className="service-title">Forms</b>
                      <div className="service-info">
                        <b className="info-title">Forms Company:</b>
                        <input
                          className="admin-input"
                          type="text"
                          defaultValue={props.orgInfo.formCompany}
                          onChange={(e) => {
                            props.setOrgInfo((prevState) => ({
                              ...prevState,
                              formCompany: e.target.value,
                            }));
                            props.handleOrgUpdate();
                          }}
                        />
                      </div>

                      <div className="service-info">
                        <b className="info-title">Forms Email:</b>
                        <input
                          className="admin-input"
                          type="text"
                          value={props.orgInfo.formEmail}
                          //defaultValue={orgInfo.formEmail}
                          onChange={(e) => {
                            props.setOrgInfo((prevState) => ({
                              ...prevState,
                              formEmail: e.target.value,
                            }));
                            props.handleOrgUpdate();
                          }}
                        />
                      </div>
                      <div className="service-info">
                        <b className="info-title">Forms Password:</b>
                        <input
                          className="admin-input"
                          type="text"
                          value={props.orgInfo.formPassword}
                          //defaultValue={orgInfo.formPassword}
                          onChange={(e) => {
                            props.setOrgInfo((prevState) => ({
                              ...prevState,
                              formPassword: e.target.value,
                            }));
                            props.handleOrgUpdate();
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {props.orgFlags.hasFiles && (
                <div className="admin-service">
                  <b className="service-title">Files</b>
                  <div className="service-info">
                    <b className="info-title">Folder ID:</b>
                    <input
                      className="admin-input"
                      type="text"
                      value={props.orgInfo.folder}
                      //defaultValue={userInfo.folder}
                      onChange={(e) => {
                        e.persist();
                        props.setUserInfo((prevState) => ({
                          ...prevState,
                          folder: e.target.value,
                        }));
                        props.handleUpdate();
                      }}
                    />
                  </div>
                </div>
              )}{" "}
                  {props.orgFlags.hasDatabase && (
                    <div className="admin-service">
                      <b className="service-title">Database</b>
                      <div className="service-info">
                        <b className="info-title">Club Name:</b>
                        <input
                          className="admin-input"
                          type="text"
                          value={props.orgInfo.clubName}
                          //defaultValue={orgInfo.clubName}
                          onChange={(e) => {
                            props.setOrgInfo((prevState) => ({
                              ...prevState,
                              clubName: e.target.value,
                            }));
                            props.handleOrgUpdate();
                          }}
                        />
                      </div>
                      <div className="service-info">
                        <b className="info-title">Firebase Auth Domain:</b>
                        <input
                          className="admin-input"
                          type="text"
                          value={props.orgInfo.firebaseDomain}
                          //defaultValue={orgInfo.firebaseDomain}
                          onChange={(e) => {
                            props.setOrgInfo((prevState) => ({
                              ...prevState,
                              firebaseDomain: e.target.value,
                            }));
                            props.handleOrgUpdate();
                          }}
                        />
                      </div>
                      <div className="service-info">
                        <b className="info-title">Database URL:</b>
                        <input
                          className="admin-input"
                          type="text"
                          value={props.orgInfo.dbURL}
                          //defaultValue={orgInfo.dbURL}
                          onChange={(e) => {
                            props.setOrgInfo((prevState) => ({
                              ...prevState,
                              dbURL: e.target.value,
                            }));
                            props.handleOrgUpdate();
                          }}
                        />
                      </div>
                      <div className="service-info">
                        <b className="info-title">Project ID:</b>
                        <input
                          className="admin-input"
                          type="text"
                          value={props.orgInfo.projID}
                          //defaultValue={orgInfo.projID}
                          onChange={(e) => {
                            props.setOrgInfo((prevState) => ({
                              ...prevState,
                              projID: e.target.value,
                            }));
                            props.handleOrgUpdate();
                          }}
                        />
                      </div>
                      <div className="service-info">
                        <b className="info-title">Storage Bucket:</b>
                        <input
                          className="admin-input"
                          type="text"
                          value={props.orgInfo.bucket}
                          //defaultValue={orgInfo.bucket}
                          onChange={(e) => {
                            props.setOrgInfo((prevState) => ({
                              ...prevState,
                              bucket: e.target.value,
                            }));
                            props.handleOrgUpdate();
                          }}
                        />
                      </div>
                      <div className="service-info">
                        <b className="info-title">Messaging Sender ID:</b>
                        <input
                          className="admin-input"
                          type="text"
                          value={props.orgInfo.senderID}
                          //defaultValue={orgInfo.senderID}
                          onChange={(e) => {
                            props.setOrgInfo((prevState) => ({
                              ...prevState,
                              senderID: e.target.value,
                            }));
                            props.handleOrgUpdate();
                          }}
                        />
                      </div>
                      <div className="service-info">
                        <b className="info-title">API Key:</b>
                        <input
                          className="admin-input"
                          type="text"
                          value={props.orgInfo.apiKey}
                          //defaultValue={orgInfo.apiKey}
                          onChange={(e) => {
                            props.setOrgInfo((prevState) => ({
                              ...prevState,
                              apiKey: e.target.value,
                            }));
                            props.handleOrgUpdate();
                          }}
                        />
                      </div>
                      <div className="service-info">
                        <b className="info-title">Membership Categories:</b>
                        <input
                          className="admin-input"
                          type="text"
                          value={props.orgInfo.memberCategories}
                          //defaultValue={orgInfo.memberCategories}
                          onChange={(e) => {
                            props.setOrgInfo((prevState) => ({
                              ...prevState,
                              memberCategories: e.target.value,
                            }));
                            props.handleOrgUpdate();
                          }}
                        />
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
    )
}