import React, { useEffect } from "react";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import { logout } from "./../actions";
import { Redirect } from "react-router-dom";
import { useLocation } from 'react-router-dom';

/*
    Logout Function

    Updates redux state and calls cognito to end session
*/
export default function Logout() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Access individual query parameters
  const sso = queryParams.get('sso');
  const user = queryParams.get('user');
  const state = queryParams.get('state');

  const dispatch = useDispatch();

  useEffect(() => {
    detachIntercom();
    signOut();
  }, []);

  function detachIntercom() {
    window.Intercom("shutdown", {});
  }

  async function signOut() {
    try {
      await Auth.signOut();
      dispatch(logout());
      sessionStorage.clear();
      localStorage.clear();

      window.IntercomShutDown();
      // window.IntercomHide();
      //window.location.reload();
      
    } catch (e) {
      console.log("Error signing out");
    }
  }

  if(sso === "true"){
    //return window.location.href = "http://localhost:3000/sso/canva";
    //return window.location.href = `https://test.digitalrange.com/sso/canva?user=${user}&state=${state}`;
    return window.location.href = `/sso/canva?user=${user}&state=${state}`;
  }else{
    return (
        <Redirect to="/Login">
          <h1> You have been logged out </h1>
        </Redirect>
      );    
  }
}
