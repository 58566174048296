import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  InputGroup,
  FormControl,
  Modal
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { AdminService, UserService } from "../../services";
import { setSelection } from "../../actions";
import Moment from "react-moment";
import personIcon from "../../assets/images/icons/personIcon.svg";
import filterIcon from "../../assets/images/icons/filter.svg";
import greenCheck from "../../assets/images/icons/green-check.svg";
import redDelete from "../../assets/images/icons/red-delete.svg";
import CustomSelect from "./custom-select";
import { failedPopup, successPopup } from "./Popup";

/*
    Separates the admin pane (LEFT SIDE)

    Contains the listing of users or orgs and can switch in between each
    
    Redux state is updated to store current user selected -> this is provided to AdminView
*/

function AdminLeft(props) {

  // for redux
  const dispatch = useDispatch();

  // group filters states
  const [groupFilters, setGroupFilters] = useState([]);
  const [currentFilteredGroups, setCurrentFilteredGroups] = useState([]);
  
  // current search state
  const [currentSearch, setCurrentSearch] = useState("");

  // number of users state
  const [numberOfUsers, setNumberOfUsers] = useState({});

  // user lists states
  const [userList, setUserList] = useState([]); // all users
  const [users, setUsers] = useState([]); // active users
  const [searchUserList, setSearchUserList] = useState([]); // current searched users (what you see)

  // organization lists states
  const [orgList, setOrgList] = useState([]);// all organizations
  const [orgs, setOrgs] = useState([]); // active organizations
  const [searchOrgList, setSearchOrgList] = useState([]); // current searched organizations (what you see)

  // current list view state (ex: "user" or "org")
  const [listView, setListView] = useState("user");

  // button state for viewing deleted users/orgs
  const [active, setActive] = useState(false);

  // button state for group filters
  const [filterActive, setFilterActive] = useState(false);

  // group filter states
  const [filteredOrgs, setFilteredOrgs] = useState([]); // organizations
  const [filteredUsers, setFilteredUsers] = useState([]);// users

  // group modal states
  const [show, setShow] = useState(false); // show modal
  const [deleteShow, setDeleteShow] = useState(false); // show group delete
  const [editShow, setEditShow] = useState(false); // show group edit
  const [groupToDelete, setGroupToDelete] = useState(""); // current group to delete
  const [groupToEdit, setGroupToEdit] = useState(""); // current group to edit

  // list loaded state (not sure what this is for...)
  const [listLoaded, setListLoaded] = useState(false);

  // useEffect for fetching data
  useEffect(() => {
    onLoad();
  }, []);

  // useEffect for refreshing the lists/views
  useEffect(() => {
    refreshList()
  }, [active, listView]);

  // useEffect for refreshing the lists/views when group filters is off
  useEffect(() => {
    if(!filterActive){
      refreshList()
    }
  }, [filterActive])

  /**
   * Fetches a list of organizations, groups, and users from the database.
   */
  const onLoad = async (e) => {
    
    var cachedOrgs = JSON.parse(localStorage.getItem('cachedOrgs'))

    

    //console.log(cachedOrgs)
    //console.log(cachedOrgs)
    var activeOrgs = cachedOrgs.filter((el) => el.deletedAt == null); // active orgs
    setOrgs(activeOrgs); // active orgs
    setOrgList(cachedOrgs); // all orgs
    setSearchOrgList(activeOrgs); // current searched orgs
    //console.log(props.cachedOrgs())

    
    // // get a list of organizations
    // var activeOrgs;

    // // check if it's cached
    // //var cachedOrgs = JSON.parse(localStorage.getItem('cachedOrgs'));
    // if(cachedOrgs){
    //   activeOrgs = cachedOrgs.filter((el) => el.deletedAt == null); // active orgs
    //   setOrgs(activeOrgs); // active orgs
    //   setOrgList(cachedOrgs); // all orgs
    //   setSearchOrgList(activeOrgs); // current searched orgs
    // }else{
    //   await AdminService.getOrgs().then((res) => {
    //     localStorage.setItem('cachedOrgs', JSON.stringify(res.data))
    //     activeOrgs = res.data.filter((el) => el.deletedAt == null); // active orgs
    //     setOrgs(activeOrgs); // active orgs
    //     setOrgList(res.data); // all orgs
    //     setSearchOrgList(activeOrgs); // current searched orgs
    //   });
    // }

    // get a list of groups
    var cachedGroups = JSON.parse(localStorage.getItem('cachedGroups')); // check if it's cached
    if(cachedGroups){
      var groups = [];
      var label = {label: "Groups", options: []};
      for(var i = 0; i < cachedGroups.length; i++){
        label.options.push({"label": cachedGroups[i].name, "value": cachedGroups[i].name});
      }
      groups.push(label);
      setGroupFilters(groups); // set the group filters
    }else{
      await AdminService.getGroups().then((res) => {
        //localStorage.setItem('cachedGroups', JSON.stringify(res.data)) <-- why no work?
        var groups = [];
        var label = {label: "Groups", options: []};
        for(var i = 0; i < res.data.length; i++){
          label.options.push({"label": res.data[i].name, "value": res.data[i].name});
        }
        groups.push(label);
        setGroupFilters(groups); // set the group filters
      })
    }
    

    // get a list of users

    
    var cachedUsers = JSON.parse(localStorage.getItem('cachedUsers')); // check if it's cached
    // set the number of users in an organization
    var tempNumUsers = {};
    activeOrgs.forEach(org => {
      tempNumUsers[org.name] = 0;
    })
    cachedUsers.forEach(user => {
      activeOrgs.reduce((acc, obj) => {
        if(obj.value.includes(user.guid)){
          tempNumUsers[obj.name]++;
        }
        return null;
      })
      setNumberOfUsers(tempNumUsers);
    })

    // set users data
    const activeUsers = cachedUsers.filter((el) => el.deletedAt == null); // active users
    setSearchUserList(activeUsers); // current searched users
    setUsers(activeUsers); // active users
    setUserList(cachedUsers); // all users
    setListLoaded(true); // lists have been loaded (forgot what this was for...)




    // if(cachedUsers){
    //   // set the number of users in an organization
    //   var tempNumUsers = {};
    //   activeOrgs.forEach(org => {
    //     tempNumUsers[org.name] = 0;
    //   })
    //   cachedUsers.forEach(user => {
    //     activeOrgs.reduce((acc, obj) => {
    //       if(obj.value.includes(user.guid)){
    //         tempNumUsers[obj.name]++;
    //       }
    //      return null;
    //     })
    //     setNumberOfUsers(tempNumUsers);
    //   })

    //   // set users data
    //   const activeUsers = cachedUsers.filter((el) => el.deletedAt == null); // active users
    //   setSearchUserList(activeUsers); // current searched users
    //   setUsers(activeUsers); // active users
    //   setUserList(cachedUsers); // all users
    //   setListLoaded(true); // lists have been loaded (forgot what this was for...)
    // }else{
    //   await AdminService.getUsers().then((res) => {
    //     localStorage.setItem('cachedUsers', JSON.stringify(res.data))

    //     // set the number of users in an organization
    //     var tempNumUsers = {};
    //     activeOrgs.forEach(org => {
    //       tempNumUsers[org.name] = 0;
    //     })
    //     res.data.forEach(user => {
    //       activeOrgs.reduce((acc, obj) => {
    //         if(obj.value.includes(user.guid)){
    //           tempNumUsers[obj.name]++;
    //         }
    //        return null;
    //       })
    //       setNumberOfUsers(tempNumUsers);
    //     })
  
    //     // set users data
    //     const activeUsers = res.data.filter((el) => el.deletedAt == null); // active users
    //     setSearchUserList(activeUsers); // current searched users
    //     setUsers(activeUsers); // active users
    //     setUserList(res.data); // all users
    //     setListLoaded(true); // lists have been loaded (forgot what this was for...)
    //   });
    // }
    
  }

  /**
   * Refreshes the lists for users and organizations.
   * if deleted users/orgs is active, show deleted users/orgs.
   * else, show active users/orgs.
   */
  const refreshList = () => {
    
    // if deleted user/org button is active
    if(active){
      showDeleted();
    }

    // if deleted user/org button is not active
    else{
      showActive();
    }
  }

  /**
   * Shows the deleted users/orgs.
   */
  const showDeleted = () => {
    // user
    if(listView === "user"){

      // group filter is active
      if(filterActive){
        const deleted = filteredUsers.filter((el) => el.deletedAt !== null); // filter for active users 
        setSearchUserList(deleted); // set current searched users
        setUsers(deleted) // current list of users (active)
      }
      
      // group filter is not active
      else{
        const deleted = userList.filter((el) => el.deletedAt !== null); // filter for deleted users
        setSearchUserList(deleted); // set current searched users 
        setUsers(deleted); // current list of users (deleted)
      }
      
    }
    
    // org
    else{

      // group filter is active
      if(filterActive){
        var deleted = filteredOrgs.filter((el) => el.deletedAt !== null); // filter for active users 
        setSearchOrgList(deleted); // set current searched users
        setOrgs(deleted) // current list of users (active)
      }
      
      // group filter is not active
      else{
        const deleted = orgList.filter((el) => el.deletedAt !== null); // filter for delted orgs
        setSearchOrgList(deleted); // set current searched orgs
        setOrgs(deleted); // current list of orgs (deleted)
      }
    }
  }

  /**
   * Shows the active users/orgs (the default).
   */
  const showActive = () => {

    // user
    if(listView === "user"){

      // group filter is active
      if(filterActive){
        const activeUsers = filteredUsers.filter((el) => el.deletedAt == null); // filter for active users 
        setSearchUserList(activeUsers); // set current searched users
        setUsers(activeUsers) // current list of users (active)
      }
      
      // group filter is not active
      else{
        const activeUsers = userList.filter((el) => el.deletedAt == null); // filter for active users
        setSearchUserList(activeUsers); // set current searched users
        setUsers(activeUsers); // current list of users (active)

        // check if there is a value in the search field 
        if(currentSearch){
          handleSearch(currentSearch, activeUsers)
        }
      } 
    }
    
    // org
    else{

      // group filter is active
      if(filterActive){
        const activeOrgs = filteredOrgs.filter((el) => el.deletedAt == null); // filter for active orgs 
        setOrgs(activeOrgs); // set current searched orgs
        setSearchOrgList(activeOrgs) // current list of orgs (active)
      }
      
      // group filter is not active
      else {
        const activeOrgs = orgList.filter((el) => el.deletedAt == null); // filter for active orgs 
        setOrgs(activeOrgs); // set current searched orgs
        setSearchOrgList(activeOrgs); // current list of orgs (active)

        // check if there is a value in the search field 
        if(currentSearch){
          handleSearch(currentSearch, activeOrgs)
        }
      }
    }
  }

  /**
   * Filters the users/orgs based on what group filter is active.
   * @param {Array.<{label: string, value: string}>} selectedOptions - the selected options from the dropdown 
   */
  const filterGroups = (selectedOptions) =>{

    // persist the filters after close
    if(!selectedOptions){
      selectedOptions = currentFilteredGroups;
    }

    // filter button
    if(selectedOptions.length === 0){
      setFilterActive(false);
    }else{
      setFilterActive(true);
    }

    var arr = [];

    // users
    if(listView === "user"){
      setSearchUserList(arr);
      
      // if group filters is off (a.k.a., you deselected all groups)
      if (selectedOptions.length === 0) {
        refreshList();
      } 
      
      // group filters is on
      else {

        // filter the users by selected groups
        users.map((el) => {
          var organization = null;
          for(var i = 0; i < orgs.length; i++){
            if(orgs[i].value === el.guid){
              organization = orgs[i];
              break;
            }
          }
          var isThere = true;
          for(i = 0; i < selectedOptions.length; i++){
            if(!organization.groups[selectedOptions[i].label.replace(/\s/g, '')]){
              isThere = false;
              break;
            }
          }
          if(isThere){
            arr.push(el);
            setSearchUserList(arr);
            setFilteredUsers(arr);
          }
          return null;
        });
      }

    }

    // orgs
    else{

      // if group filters is off (a.k.a., you deselected all groups)
      if (selectedOptions.length === 0) {
        refreshList();
      } 
      
      // group filters is on
      else {

        // filter the orgs by selected groups
        orgs.map((el) => {
          var isThere = true;
          for(var i = 0; i < selectedOptions.length; i++){
            if(!el.groups[selectedOptions[i].label]){
              isThere = false;
              break;
            }
          }
          if(isThere){
            arr.push(el);
            setSearchOrgList(arr);
            setFilteredOrgs(arr);
          }
          return null;
        });
      }
    }
    setCurrentFilteredGroups(selectedOptions);

    // if there is a current value in the search bar
    if(currentSearch && arr.length > 1){
      handleSearch(currentSearch, arr)
    }
  }

  /**
   * Handler for the search field.
   * @param {object|string} e - this is the value in the search bar: object - from typing in the search bar; string - from filter button use
   * @param {Array.<{value: string, name: string, groups: object}>} groupFilters - for filter button: a list of current users/orgs to search
   */
  const handleSearch = (e, filteredList) => {

    // set the current search value
    var value;
    if(typeof e === 'object'){
      value = e.target.value.toLowerCase();
    }else if (typeof e === "string"){
      value = e.toLowerCase();
    }
    setCurrentSearch(value)
    
    var arr = [];
    // filter the users
    if (listView === "user") {

      // this checks to see if there's a group filter already active
      var usersToFilter;
      if(currentFilteredGroups.length > 0 || filteredList){
        //orgsToFilter = filteredOrgs;
        filteredList ? usersToFilter = filteredList : usersToFilter = filteredUsers;
      }else{
        usersToFilter = users;
      }

      // user search filter based on: (email, first name, last name)
      usersToFilter.filter((item) => {
          let email = item.email.toLowerCase(); // email
          let fname = item.firstName.toLowerCase(); // first name
          let lname = item.lastName.toLowerCase(); // last name
        return value.toLowerCase() === '' 
          ? item 
          : (email.includes(value) || fname.includes(value) || lname.includes(value))
      }).map((item) => {
        arr.push(item)
        return null
      })
      setSearchUserList(arr);
    } 
    
    // filter the orgs
    else {

        // this checks to see if there's a group filter already active
        var orgsToFilter;
        if(currentFilteredGroups.length > 0 || filteredList){
          filteredList ? orgsToFilter = filteredList : orgsToFilter = filteredOrgs;
        }else{
          orgsToFilter = orgs;
        }

        // group search filter based on: (name)
        orgsToFilter.filter((item) => {
          return value.toLowerCase() === '' 
            ? item 
            : item.name.toLowerCase().includes(value)
        }).map((item) => {
          arr.push(item)
          return null
        })
        setSearchOrgList(arr);
  };
}
  
  /**
   * Handler for the delete group modal.
   * @param {string} groupToDelete - the current group selected to be deleted
   */
  const handleDeleteShow = (groupToDelete) =>{
    setGroupToDelete(groupToDelete);
    setDeleteShow(true);
  }

  /**
   * Handler for the edit group modal.
   * @param {string} groupToEdit - the current group selected to be edited
   */
  const handleEditShow = (groupToEdit) =>{
    setGroupToEdit(groupToEdit);
    setEditShow(true);
  }

  /**
   * Handler for switching the table view.
   * @param {object} e - event
   * @param {string} x - the list view to be set to: "user" | "org" 
   */
  const handleSwitch = (e, x) => {
    setCurrentSearch("") // reset the search field
    e.preventDefault();
    setListView(x);
  }

  /**
   * Handler for selecting an user/org and displays their information in AdminView. Uses redux to store the state.
   * @param {object} e - event 
   * @param {string} actor - name of the user/org being selected
   * @param {string} id - uuid of a user or guid of an org
   */
  const selectActor = async (e, actor, id) => {
    e.preventDefault();

    //console.log(actor, id)

    // users
    if (listView === 'user') {

      // get user information

      var cachedUsers = JSON.parse(localStorage.getItem('cachedUsers')); 
      var cachedOrgs = JSON.parse(localStorage.getItem('cachedOrgs'));
      const user = cachedUsers.filter((item) => item.uuid === id);
      const org = cachedOrgs.filter((item) => item.value === user[0].guid);

      // send to redux so AdminView can use 
      dispatch(
        setSelection({
          actor: {
            name: actor,
            id: id,
            type: "user",
          },
          user: {
            name: actor,
            id: id,
          },
          org: {
            name: org[0].name.replaceAll("_", " "),
            id: user[0].guid,
          },
        })
      );

      // await UserService.getUser(id).then((res) => {
      //   UserService.getOrgName(res.data.guid).then((rex) => {

      //     // send to redux so AdminView can use 
      //     dispatch(
      //       setSelection({
      //         actor: {
      //           name: actor,
      //           id: id,
      //           type: "user",
      //         },
      //         user: {
      //           name: actor,
      //           id: id,
      //         },
      //         org: {
      //           name: rex.data.name.replaceAll("_", " "),
      //           id: res.data.guid,
      //         },
      //       })
      //     );
      //   });
      // });
    }

    // orgs
    else {
      // send to redux so AdminView can use 
      dispatch(
        setSelection({
          actor: {
            name: actor,
            id: id,
            type: "org",
          },
          user: {
            name: "",
            id: "",
          },
          org: {
            name: actor.replaceAll("_", " "),
            id: id,
          },
        })
      );
    }
  }

  /**
   * Handler for creating a new user/org. Uses redux.
   * @param {object} e - event
   * @param {string} x - the type of object being created (user/org)
   */
  const handleCreate = (e, x) => {
    e.preventDefault();

    // send to redux
    dispatch(
      setSelection({
        actor: {
          name: "blank" + x,
          id: "blank",
          type: x,
        },
        user: {
          name: "blank",
          id: "blank",
        },
        org: {
          name: "blank",
          id: "blank",
        },
      })
    );
  }

  /**
   * Handler for editing the name of a group.
   * @param {string} oldName - old group name (before) 
   * @param {string} newName - new group name (after)
   */
  const handleEditGroup = (oldName, newName) => {
    AdminService.updateGroup({oldName: oldName, newName: newName}) // update the group in the database
    window.location.reload(); // reload after editing
  }

  /**
   * Handler for creating a new group.
   * @param {string} newGroup - the name of the new group to be created
   */
  const handleGroupCreate = (newGroup) => {

    // check to see if the group already exists
    var alreadyExists = false;
    groupFilters[0].options.every(option => {
      if(option.label === newGroup){
        failedPopup(`"${newGroup}" already exists!`);
        alreadyExists = true;
        return false;
      }
      return true;
    })

    // if the group doesn't exist
    if(!alreadyExists) {
      localStorage.setItem('cacheUpdate', true);
      successPopup(`"${newGroup}" has been created!`);
      AdminService.createGroup({groupName: newGroup}) // add group to database
      window.location.reload(); // reload after creating
    }
  }

  /**
   * Handler for deleting a group.
   * @param {string} name - name of the group to be deleted 
   */
  const handleDeleteGroup = (name) => {
    localStorage.setItem('cacheUpdate', true);
    setDeleteShow(false);
    AdminService.deleteGroup({groupName: name}) // delete the group from the database
    successPopup(`"${name}" Deleted Successfully`);
    window.location.reload(); // reload after deleting
  }
  
  // Render function.
  return (
    <div className="admin-left">
      <div className="dashboard-control">
        <div className="listing-switch">
          <Button
            className={listView === "user" ? "btn-ab" : "btn-bb"}
            onClick={(e) => handleSwitch(e, "user")}
            data-toggle="button"
            aria-pressed="true"
          >
            Users
          </Button>
          <Button
            className={listView === "org" ? "btn-ab" : "btn-bb"}
            onClick={(e) => handleSwitch(e, "org")}
            data-toggle="button"
            aria-pressed="true"
          >
            Organizations
          </Button>
        </div>
        <div className="create-button">
          {listView === "user" ? (
            <Button
              variant="aa"
              onClick={(e) => handleCreate(e, "user")}
              className="btn-aa"
              data-toggle="button"
              aria-pressed="true"
            >
              Create User
            </Button>
          ) : (
            <Button
              variant="aa"
              onClick={(e) => handleCreate(e, "org")}
              className="btn-aa"
              data-toggle="button"
              aria-pressed="true"
            >
              Create Organization
            </Button>
          )}
        </div>
      </div>
      <div className="search">
        <InputGroup>
          <FormControl
            type="text"
            className="admin-filter-control"
            placeholder="Search"
            defaultValue=""
            value={currentSearch}
            onChange={handleSearch}
          />
        <div  style={{display: 'flex'}}>
        <Button
          type="button"
          variant="info"
          //className="btn-info"
          className={
            !filterActive ? "btn-info-end " : "btn-info-end btn-active"
          }
          id="input-group-dropdown-2"
          align="end"
          title=""
          //value={active}
          onClick={() => setShow(true)}
        >
          <img src={filterIcon} width="20px" height="20px" alt=""/>
        </Button>
        <div className="test">
          <Modal
            show={show} 
            onHide={() => setShow(false)} 
            size='sm' 
            dialogClassName='custom-dialog'
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Group Filters
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CustomSelect 
                className="customSelect"   
                options={groupFilters}
                onChange={filterGroups}
                handleDeleteShow={handleDeleteShow}
                handleEditShow={handleEditShow}
                onCreateOption={handleGroupCreate}
                defaultValue={currentFilteredGroups}
              />
            </Modal.Body>
          </Modal>
        </div>
          

          {/* DELETE CONFIRMATION MODAL */}
          <Modal
              show={deleteShow} 
              onHide={() => setDeleteShow(false)} 
              size='lg' 
              dialogClassName='delete-confirmation'
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Are you sure you want to delete "{groupToDelete}"?
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="editGroup-modal">
                <Button
                  type="button"
                  className="btn-edit"
                  id="input-group-dropdown-2"
                  align="end"
                  title=""
                  value={active}
                  onClick={() => setDeleteShow(false)}
                >
                  NO
                </Button>
                <Button
                  type="button"
                  className="btn-cancel"
                  id="input-group-dropdown-2"
                  align="end"
                  title=""
                  value={active}
                  onClick={() => {
                    handleDeleteGroup(groupToDelete);
                    setEditShow(false)
                  }}
                >
                  YES
                </Button>
              </Modal.Body>
            </Modal>

            {/* EDIT MODAL */}
            <Modal
              show={editShow} 
              onHide={() => setEditShow(false)} 
              size='sm' 
              dialogClassName='edit-modal'
            >
              <Modal.Header closeButton>
                <Modal.Title style={{display: "flex"}}>
                  <input type="text" id="editGroup" defaultValue={groupToEdit} className="admin-input"/>
                  <Button 
                    onClick={() => {
                      setEditShow(false)
                      handleEditGroup(groupToEdit, document.getElementById("editGroup").value);
                    }}
                    className="btn bg-transparent btn-xs"
                    width="10px"
                    height="10px"
                  >
                    <img src={greenCheck} width="14px" height="14px" alt=""/>
                  </Button>
                  <Button 
                    onClick={() => handleDeleteShow(groupToEdit)}
                    className="btn bg-transparent"
                  >
                    <img src={redDelete} width="14px" height="14px" alt=""/>
                  </Button>
                </Modal.Title>
              </Modal.Header>
            </Modal>
          
          
      </div>
      <Button
              type="button"
              variant="info"
              className={
                !active ? "btn-info-end " : "btn-info-end btn-active"
              }
              id="input-group-dropdown-2"
              align="end"
              title=""
              
              onClick={() => {setActive(!active); }}
            >
              <img src={personIcon} width="20px" height="20px" alt=""/>
            </Button>
          

        </InputGroup>
      </div>
      <div className="name-listing">
        {listLoaded && (
          <Table className="a-table">
            <thead>
              <tr>
                <th scope="col">Name</th>
                {listView === "user" ? <th scope="col">Last Seen</th> : <th scope="col">Number of Users</th>}
              </tr>
            </thead>
            {listView === "user" ? (
              <tbody>
                {searchUserList.length > 0 
                  ? searchUserList.map((actor, ind) => (
                    <tr
                      onClick={(e) =>
                        selectActor(
                          e,
                          actor.firstName + " " + actor.lastName,
                          actor.uuid
                        )
                      }
                      key={ind}
                    >
                      <td key="name">{actor.firstName + " " + actor.lastName}</td>
                      <td key="last-seen">
                        <Moment format="M/D/YY h:mm A">
                          {actor.updatedAt}
                        </Moment>
                      </td>
                    </tr>
                    ))
                  :<p className='noResults'>No matching results found</p>}
              </tbody>
            ) : (
              <tbody>
                {searchOrgList.map((actor, ind) => (
                  <tr
                    onClick={(e) => selectActor(e, actor.name, actor.value)}
                    key={ind}
                  >
                    <td key="name">{actor.name.replaceAll("_", " ")}</td>
                    <td key="last-seen">{numberOfUsers[actor.name]}</td>
                  </tr>
                ))}
              </tbody>
            )}
          </Table>
        )}
      </div>
    </div>
  );
}

export default AdminLeft;