import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  FormLabel,
  FormControl,
} from "react-bootstrap";
import SelectSearch from "react-select-search";
import { useDispatch } from "react-redux";
import { setCreateOrg } from "../../actions";
import CreatableSelect from "react-select/creatable";

import { AdminService } from "../../services";
import { generateRandomString } from "../../common/utils";


/*
    Create org page

    blank form for new org submission
*/

export default function CreateOrg(props) {
  useEffect(() => {
    onLoad();
  }, []);

  const [groups, setGroups] = useState([]);
  const [defaultSelected, setDefaultSelected] = useState([]);

  const dispatch = useDispatch();

  const [orgInfo, setOrgInfo] = useState({
    formCompany: "",
    formEmail: "",
    formPassword: "",
    companyName: "",
    hasLogo: false,
    hasImage: false,
    clubName: "",
    firebaseDomain: "",
    dbURL: "",
    projID: "",
    groups: {},
    bucket: "",
    senderID: "",
    apiKey: "",
    randomId: generateRandomString(),
    memberCategories: "",
    logoData: null,
    backData: null,
  });

  const [orgFlags, setOrgFlags] = useState({
    groups: {},
    hasForms: false,
    hasDatabase: false,
  });

  useEffect(() => {
    handleOrgUpdate();
  }, [orgInfo, orgFlags]);

  async function onLoad(){
    await AdminService.getGroups().then((res) => {
      var tempGroups = [];
      var label = {label: "Groups", options: []};
      for(var i = 0; i < res.data.length; i++){
        label.options.push({"value": String(i + 1), "name": res.data[i].name});
      }
      tempGroups.push(label); 
      setGroups(tempGroups[0].options);
    })
    setData();
  }

  function handleButtonClick(e, x) {
    e.preventDefault();

    setOrgFlags((prevState) => ({
      ...prevState,
      [x]: !orgFlags[x],
    }));

    dispatch(
      setCreateOrg({
        org: orgInfo,
        orgFlags: orgFlags,
      })
    );
  }

  function handleSelectSearch(e) {
    setDefaultSelected(e);
    var selectedGroups = [];
    var tempGroups = {}
    for(var i in groups){
      tempGroups[groups[i].name] = 0;
      if(e.includes(groups[i].value)){
        selectedGroups.push(groups[i].name)
      }
    }
    if (e.length) {
      e.map((el) => parseInt(el));
      let base = {
        groups: tempGroups,
        hasForms: orgFlags.hasForms,
        hasDatabase: orgFlags.hasDatabase,
      };
      var test = tempGroups;
      for(var key in test){
        if(selectedGroups.includes(key)){
          tempGroups[key] = 1;
        }else{
          tempGroups[key] = 0;
        }
      }
      setOrgFlags({
        ...base,
        groups: tempGroups
      });

      setOrgInfo((prevState) => ({
        ...prevState,
        groups: tempGroups,
      }));
    }
  }

  function handleOrgUpdate() {
    dispatch(
      setCreateOrg({
        org: orgInfo,
        orgFlags: orgFlags,
        new: true,
      })
    );
  }

  function setData() {
    setDefaultSelected([]);
    var tempSelected = [];
    for(var key in orgInfo.groups){
      if(orgInfo.groups[key] === 1){
        for(var i in groups){
          if(groups[i].name === key){
            tempSelected.push(groups[i].value)
          }
        }
      }
    }
    setDefaultSelected(tempSelected);
  }


  return (
    <div className="admin-interior-view admin-view-grid">
      <div className="admin-org-left">
        <Row className="admin-row">
          <Col className="admin-col">
            <b className="info-title create-title">Organization Name: </b>
            <input
              className="h1-a admin-create-input"
              id="Org"
              value={orgInfo.orgName}
              onChange={(e) => {
                e.persist();

                setOrgInfo((prevState) => ({
                  ...prevState,
                  orgName: e.target.value,
                }));
                handleOrgUpdate();
              }}
              autoFocus
              type="text"
            />
          </Col>
        </Row>
        <Row className="a-row">
          <Col className="admin-col col-little a-col">
            <b className="info-title">Logo: </b>
            {orgInfo.hasImage ? (
              <figure className="admin-org-logo">
                <img
                  src={
                    "https://digital-range-v2.s3.amazonaws.com/organizationLogos/" +
                    orgInfo.companyName +
                    "_Logo.png"
                  }
                  alt=""
                />
              </figure>
            ) : (
              <FormLabel className="a-label">
                <FormControl
                  type="file"
                  className="custom-file-input"
                  onChange={(e) => {
                    const data = new FormData();
                    data.append("logo", e.target.files[0], orgInfo.randomId);
                    setOrgInfo((prevState) => ({
                      ...prevState,
                      logoData: data,
                      hasLogo: true,
                    }));
                    handleOrgUpdate();
                  }}
                />
              </FormLabel>
            )}
          </Col>
          <Col className="admin-col a-col">
            <b className="info-title">Background Image: </b>
            {orgInfo.hasImage ? (
              <img
                className="admin-org-bg"
                src={
                  "https://digital-range-v2.s3.amazonaws.com/organizationBackgrounds/" +
                  orgInfo.companyName +
                  "_BG.jpg"
                }
                alt=""
              />
            ) : (
              <FormLabel className="a-label">
                <FormControl
                  type="file"
                  className="custom-file-input"
                  onChange={(e) => {
                    const data = new FormData();
                    data.append("back", e.target.files[0], orgInfo.randomId);
                    setOrgInfo((prevState) => ({
                      ...prevState,
                      backData: data,
                      hasImage: true,
                    }));
                    handleOrgUpdate();
                  }}
                />
              </FormLabel>
            )}
          </Col>
        </Row>
        <Row>
          <Col className="admin-col a-col">
            <b className="info-title">Groups: </b>
            <SelectSearch
              closeOnSelect={false}
              printOptions="on-focus"
              multiple
              placeholder="Select Groups"
              value={defaultSelected}
              onChange={(e) => {
                handleSelectSearch(e);
                handleOrgUpdate();
              }}
              options={groups}
            />
          </Col>
        </Row>
      </div>
      <div className="admin-org-right">
        <div className="actor-org-services">
          <Button
            className={orgFlags.hasEmail ? "btn-bc" : "btn-bd"}
            onClick={(e) => handleButtonClick(e, "hasEmail")}
          >
            <i className="icon-user icon-f"></i>Email
          </Button>
          <Button
            className={orgFlags.hasForms ? "btn-bc" : "btn-bd"}
            onClick={(e) => handleButtonClick(e, "hasForms")}
          >
            <i className="icon-user icon-f"></i>Forms
          </Button>
          <Button
            className={orgFlags.hasFiles ? "btn-bc" : "btn-bd"}
            onClick={(e) => handleButtonClick(e, "hasFiles")}
          >
            <i className="icon-user icon-db"></i>Files
          </Button>
          <Button
            className={orgFlags.hasDatabase ? "btn-bc" : "btn-bd"}
            onClick={(e) => handleButtonClick(e, "hasDatabase")}
          >
            <i className="icon-user icon-db"></i>Database
          </Button>
        </div>
        {orgFlags.hasEmail && (
          <div className="admin-service">
          <b className="service-title">Email</b>
          <div className="service-info">
            <b className="info-title">Client ID:</b>
            <input
              className="admin-input"
              type="text"
              value={orgInfo.clientID}
              onChange={(e) => {
                e.persist();
                setOrgInfo((prevState) => ({
                  ...prevState,
                  clientID: e.target.value,
                }));

                handleOrgUpdate();
              }}
            />
          </div>
          <div className="service-info">
            <b className="info-title">Add Subscriber Link:</b>
            <input
              className="admin-input"
              type="text"
              value={orgInfo.subLink}
              onChange={(e) => {
                e.persist();
                setOrgInfo((prevState) => ({
                  ...prevState,
                  subLink: e.target.value,
                }));

                handleOrgUpdate();
              }}
            />
          </div>
        </div>
        )}
        {orgFlags.hasForms && (
          <div className="admin-service">
            <b className="service-title">Forms</b>
            <div className="service-info">
              <b className="info-title">Forms Company:</b>
              <input
                className="admin-input"
                type="text"
                value={orgInfo.formCompany}
                onChange={(e) => {
                  e.persist();
                  setOrgInfo((prevState) => ({
                    ...prevState,
                    formCompany: e.target.value,
                  }));

                  handleOrgUpdate();
                }}
              />
            </div>

            <div className="service-info">
              <b className="info-title">Forms Email:</b>
              <input
                className="admin-input"
                type="text"
                value={orgInfo.formEmail}
                onChange={(e) => {
                  e.persist();
                  setOrgInfo((prevState) => ({
                    ...prevState,
                    formEmail: e.target.value,
                  }));

                  handleOrgUpdate();
                }}
              />
            </div>
            <div className="service-info">
              <b className="info-title">Forms Password:</b>
              <input
                className="admin-input"
                type="text"
                value={orgInfo.formPassword}
                onChange={(e) => {
                  e.persist();
                  setOrgInfo((prevState) => ({
                    ...prevState,
                    formPassword: e.target.value,
                  }));

                  handleOrgUpdate();
                }}
              />
            </div>
          </div>
        )}
        {orgFlags.hasFiles && (
          <div className="admin-service">
          <b className="service-title">Files</b>
          <div className="service-info">
            <b className="info-title">Folder ID:</b>
            <input
              className="admin-input"
              type="text"
              value={orgInfo.folderID}
              onChange={(e) => {
                e.persist();
                setOrgInfo((prevState) => ({
                  ...prevState,
                  folder: e.target.value,
                }));

                handleOrgUpdate();
              }}
            />
          </div>
        </div>
        )} 
        {orgFlags.hasDatabase && (
          <div className="admin-service">
            <b className="service-title">Database</b>
            <div className="service-info">
              <b className="info-title">Club Name:</b>
              <input
                className="admin-input"
                type="text"
                value={orgInfo.clubName}
                onChange={(e) => {
                  e.persist();
                  setOrgInfo((prevState) => ({
                    ...prevState,
                    clubName: e.target.value,
                  }));

                  handleOrgUpdate();
                }}
              />
            </div>
            <div className="service-info">
              <b className="info-title">Firebase Auth Domain:</b>
              <input
                className="admin-input"
                type="text"
                value={orgInfo.firebaseDomain}
                onChange={(e) => {
                  e.persist();
                  setOrgInfo((prevState) => ({
                    ...prevState,
                    firebaseDomain: e.target.value,
                  }));

                  handleOrgUpdate();
                }}
              />
            </div>
            <div className="service-info">
              <b className="info-title">Database URL:</b>
              <input
                className="admin-input"
                type="text"
                value={orgInfo.dbURL}
                onChange={(e) => {
                  e.persist();
                  setOrgInfo((prevState) => ({
                    ...prevState,
                    dbURL: e.target.value,
                  }));

                  handleOrgUpdate();
                }}
              />
            </div>
            <div className="service-info">
              <b className="info-title">Project ID:</b>
              <input
                className="admin-input"
                type="text"
                value={orgInfo.projID}
                onChange={(e) => {
                  e.persist();
                  setOrgInfo((prevState) => ({
                    ...prevState,
                    projID: e.target.value,
                  }));

                  handleOrgUpdate();
                }}
              />
            </div>
            <div className="service-info">
              <b className="info-title">Storage Bucket:</b>
              <input
                className="admin-input"
                type="text"
                value={orgInfo.bucket}
                onChange={(e) => {
                  e.persist();
                  setOrgInfo((prevState) => ({
                    ...prevState,
                    bucket: e.target.value,
                  }));

                  handleOrgUpdate();
                }}
              />
            </div>
            <div className="service-info">
              <b className="info-title">Messaging Sender ID:</b>
              <input
                className="admin-input"
                type="text"
                value={orgInfo.senderID}
                onChange={(e) => {
                  e.persist();
                  setOrgInfo((prevState) => ({
                    ...prevState,
                    senderID: e.target.value,
                  }));

                  handleOrgUpdate();
                }}
              />
            </div>
            <div className="service-info">
              <b className="info-title">API Key:</b>
              <input
                className="admin-input"
                type="text"
                value={orgInfo.apiKey}
                onChange={(e) => {
                  e.persist();
                  setOrgInfo((prevState) => ({
                    ...prevState,
                    apiKey: e.target.value,
                  }));

                  handleOrgUpdate();
                }}
              />
            </div>
            <div className="service-info">
              <b className="info-title">Membership Categories:</b>
              <CreatableSelect
                isMulti
                onChange={(e) => {
                  setOrgInfo((prevState) => ({
                    ...prevState,
                    memberCategories: e,
                  }));
                  handleOrgUpdate();
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

/*

<input className="admin-input" type="text" value={orgInfo.memberCategories} onChange={e => {
                                e.persist();
                                setOrgInfo(prevState => ({
                                    ...prevState,
                                    memberCategories: e.target.value
                                }));

                                handleOrgUpdate();
                            }} />

                            */
